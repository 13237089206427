import { createTheme, keyframes } from '@mui/material/styles';

const smoothPulse = keyframes`
  0% {
    box-shadow: 0 0 10px 0px rgba(138, 63, 252, 0.7);
  }
  50% {
    box-shadow: 0 0 20px 4px rgba(138, 63, 252, 1);
  }
  100% {
    box-shadow: 0 0 10px 0px rgba(138, 63, 252, 0.7);
  }
`;

const getTheme = (themeMode) =>
    createTheme({
        palette:
            themeMode === 'dark'
                ? {
                    mode: 'dark',
                    primary: {
                        main: '#8A3FFC',
                        contrastText: '#fff',
                    },
                    secondary: {
                        main: '#E63946',
                        contrastText: '#fff',
                    },
                    background: {
                        default: '#121212',
                        paper: '#1e1e1e',
                    },
                    text: {
                        primary: '#ffffff',
                        secondary: '#B0BEC5',
                    },
                }
                : {
                    mode: 'light',
                    primary: {
                        main: '#1D3557',
                        contrastText: '#fff',
                    },
                    secondary: {
                        main: '#E63946',
                        contrastText: '#fff',
                    },
                    background: {
                        default: '#ffffff',
                        paper: '#ffffff',
                    },
                    text: {
                        primary: '#1D3557',
                        secondary: '#457B9D',
                    },
                },
        typography:
            themeMode === 'dark'
                ? {
                    fontFamily: '"Poppins", "Roboto", sans-serif',
                    h6: {
                        fontWeight: 700,
                        color: '#ffffff',
                    },
                    h7: {
                        fontWeight: 500,
                    },
                    button: {
                        fontWeight: 600,
                        color: '#fff',
                    },
                    body1: {
                        color: '#ffffff',
                    },
                }
                : {
                    fontFamily: 'Poppins, Arial, sans-serif',
                    h6: {
                        fontWeight: 600,
                        color: '#F1FAEE',
                    },
                    h7: {
                        fontWeight: 450,
                    },
                    button: {
                        fontWeight: 500,
                        color: '#F1FAEE',
                    },
                    body1: {
                        color: '#ffffff',
                    },
                },
        components: {
            MuiButton: {
                styleOverrides:
                    themeMode === 'dark'
                        ? {
                            root: {
                                borderRadius: '12px',
                                textTransform: 'none',
                                padding: '10px 20px',
                                backgroundColor: '#8A3FFC',
                                color: '#fff',
                                transition: 'background 0.3s ease',
                                '&:hover': {
                                    backgroundColor: '#7A2EDC',
                                },
                            },
                        }
                        : {
                            root: {
                                borderRadius: 12,
                                textTransform: 'none',
                                padding: '10px 20px',
                            },
                        },
            },
            MuiTypography: {
                styleOverrides: {
                    gutterBottom: {
                        marginBottom: '0.2em',
                    },
                    body1: {
                        color: themeMode === 'dark' ? '#ffffff' : '#1D3557',
                    },
                    h6: {
                        color: themeMode === 'dark' ? '#ffffff' : '#1D3557',
                    },
                },
            },
            MuiCard: {
                styleOverrides:
                    themeMode === 'dark'
                        ? {
                            root: {
                                borderRadius: '16px',
                                transition: 'transform 0.3s ease, box-shadow 0.3s ease',
                                background: 'linear-gradient(145deg, #1e1e1e, #2a2a2a)',
                                boxShadow: '0 0 10px rgba(138, 63, 252, 0.5)',
                                '&:hover': {
                                    transform: 'translateY(-5px)',
                                    animation: `${smoothPulse} 2s infinite`,
                                },
                            },
                        }
                        : {
                            root: {
                                borderRadius: '16px',
                            },
                        },
            },
            MuiAppBar: {
                styleOverrides:
                    themeMode === 'dark'
                        ? {
                            root: {
                                backgroundColor: '#8A3FFC',
                                boxShadow: 'none',
                            },
                        }
                        : {
                            root: {
                                backgroundColor: '#1D3557',
                                boxShadow: 'none',
                            },
                        },
            },
            MuiBottomNavigation: {
                styleOverrides: {
                    root: {
                        backgroundColor: '#FFFFFF',
                        boxShadow:
                            themeMode === 'dark'
                                ? '0px -2px 10px rgba(0, 0, 0, 0.7)'
                                : '0px -2px 10px rgba(0, 0, 0, 0.1)',
                    },
                },
            },
            MuiIconButton: {
                styleOverrides: {
                    root: {
                        color: themeMode === 'dark' ? '#ffffff' : '#1D3557',
                    },
                },
            },
            MuiDialog: {
                styleOverrides: {
                    paper: {
                        borderRadius: 12,
                    },
                },
            },
            MuiTextField: {
                styleOverrides:
                    themeMode === 'dark'
                        ? {
                            root: {
                                borderRadius: '16px',
                                background: '#292929',
                                boxShadow: 'inset 4px 4px 8px #1e1e1e, inset -4px -4px 8px #323232',
                                '& .MuiInputBase-root': {
                                    color: '#ffffff',
                                },
                                '& .MuiOutlinedInput-notchedOutline': {
                                    borderRadius: '16px',
                                    borderColor: '#555',
                                },
                                '&:hover .MuiOutlinedInput-notchedOutline': {
                                    borderColor: '#777',
                                },
                                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                    borderColor: '#8A3FFC',
                                },
                            },
                        }
                        : {
                            root: {
                                borderRadius: 16,
                                boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
                                '& .MuiInputBase-root': {
                                    color: '#1D3557',
                                },
                                '& .MuiOutlinedInput-notchedOutline': {
                                    borderColor: '#457B9D',
                                    borderRadius: 16,
                                },
                                '&:hover .MuiOutlinedInput-notchedOutline': {
                                    borderColor: '#1D3557',
                                    borderRadius: 16,
                                },
                                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                    borderColor: '#1D3557',
                                    borderRadius: 16,
                                },
                            },
                        },
            },
            MuiInputLabel: {
                styleOverrides: {
                    root: {
                        color: themeMode === 'dark' ? '#ffffff' : '#1D3557',
                        '&.Mui-focused': {
                            color: themeMode === 'dark' ? '#ffffff' : '#1D3557',
                        },
                    },
                },
            },
            MuiMenuItem: {
                styleOverrides:
                    themeMode === 'dark'
                        ? {
                            root: {
                                paddingLeft: '16px',
                                '&:hover': {
                                    backgroundColor: '#333',
                                },
                                '&.Mui-selected': {
                                    backgroundColor: '#8A3FFC',
                                    color: '#fff',
                                },
                            },
                        }
                        : {
                            root: {
                                color: '#1D3557',
                                backgroundColor: '#ffffff',
                                paddingLeft: '16px',
                                '&:hover': {
                                    color: '#1D3557',
                                },
                                '&.Mui-selected': {
                                    backgroundColor: '#457B9D',
                                    color: '#ffffff',
                                    '&:hover': {
                                        backgroundColor: '#1D3557',
                                    },
                                },
                            },
                        },
            },
            MuiAutocomplete: {
                styleOverrides:
                    themeMode === 'dark'
                        ? {
                            listbox: {
                                '& .MuiAutocomplete-option': {
                                    color: '#ffffff',
                                    '&[aria-selected="true"]': {
                                        backgroundColor: '#8A3FFC',
                                        color: '#fff',
                                    },
                                    '&.Mui-focused': {
                                        backgroundColor: '#8A3FFC',
                                        color: '#fff',
                                    },
                                },
                            },
                        }
                        : {
                            listbox: {
                                '& .MuiAutocomplete-option': {
                                    color: '#1D3557',
                                    '&[aria-selected="true"]': {
                                        backgroundColor: '#457B9D',
                                        color: '#ffffff',
                                    },
                                    '&.Mui-focused': {
                                        backgroundColor: '#1D3557',
                                        color: '#ffffff',
                                    },
                                },
                            },
                        },
            },
            MuiSelect: {
                styleOverrides:
                    themeMode === 'dark'
                        ? {
                            root: {
                                borderRadius: '16px',
                                backgroundColor: '#292929',
                                boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.4)',
                                '&:hover .MuiOutlinedInput-notchedOutline': {
                                    borderColor: '#8A3FFC',
                                },
                                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                    borderColor: '#8A3FFC',
                                },
                                '& .MuiSelect-icon': {
                                    color: '#ffffff',
                                },
                            },
                        }
                        : {
                            root: {
                                borderRadius: 16,
                                backgroundColor: '#ffffff',
                                boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
                                '&:hover .MuiOutlinedInput-notchedOutline': {
                                    borderColor: '#1D3557',
                                },
                                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                    borderColor: '#1D3557',
                                },
                                '& .MuiSelect-icon': {
                                    color: '#1D3557',
                                },
                            },
                        },
            },
        },
    });

export default getTheme;
