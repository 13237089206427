import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {getGroups} from "../../../api/api";

export const fetchGroups = createAsyncThunk(
    'groupsSlice/fetchGroups',
    async (filter = {}, { rejectWithValue }) => {
        try {
            const data = await getGroups();
            return data.data;
        } catch (error) {
            return rejectWithValue(error.response?.data || 'Error fetching user list');
        }
    }
);

const groupsSlice = createSlice({
    name: 'groups',
    initialState: {
        groupsList: [],
        loading: false,
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchGroups.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchGroups.fulfilled, (state, action) => {
                state.loading = false;
                state.groupsList = action.payload;
            })
            .addCase(fetchGroups.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            });
    },
});

export default groupsSlice.reducer;
