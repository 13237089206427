import React, { useState, useEffect } from 'react';
import {
    Container,
    Box,
    Typography,
    TextField,
    Button,
    Snackbar,
    Alert,
    Avatar,
    Chip,
    IconButton,
    InputAdornment,
} from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import CheckIcon from '@mui/icons-material/Check';
import { useDispatch, useSelector } from 'react-redux';
import { fetchUserList } from '../../../../service/redux/reducers/userListSlice';
import AnimatedBox from '../../../../components/MotionChild/AnimatedBox';
import { roleEmojis } from '../../../../utils/roleEmojis';
import { createGroup, updateGroup } from '../../../../api/api';
import { fetchGroups } from '../../../../service/redux/reducers/groupsSlice';
import { useNavigate, useLocation } from 'react-router-dom';
import { emailRegex } from '../../../../utils/emailRegex';

const AddEditGroupScreen = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const editMode = location.state?.mode === 'edit';
    const groupData = location.state?.group || {};
    const { userList, loading } = useSelector((state) => state.usersList);

    const [groupLabel, setGroupLabel] = useState(editMode ? groupData.label : '');
    const [groupDescription, setGroupDescription] = useState(editMode ? groupData.description : '');
    const [selectedUsers, setSelectedUsers] = useState(editMode ? groupData.contacts : []);
    const [inputValue, setInputValue] = useState('');
    const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });

    useEffect(() => {
        dispatch(fetchUserList());
    }, [dispatch]);

    const handleAddEmail = () => {
        const trimmed = inputValue.trim();
        if (emailRegex.test(trimmed) && !selectedUsers.includes(trimmed)) {
            setSelectedUsers([...selectedUsers, trimmed]);
        }
        setInputValue('');
    };

    const handleKeyDown = (event) => {
        if (event.key === 'Enter' || event.key === ' ') {
            event.preventDefault();
            handleAddEmail();
        }
    };

    const handleSubmit = async () => {
        try {
            const payload = {
                label: groupLabel,
                description: groupDescription,
                contacts: selectedUsers.map((item) =>
                    typeof item === 'string' ? item : item.email
                ),
            };
            if (editMode) {
                await updateGroup(groupData.id, payload);
                setSnackbar({ open: true, message: 'Группа успешно обновлена', severity: 'success' });
            } else {
                await createGroup(payload);
                setSnackbar({ open: true, message: 'Группа успешно создана', severity: 'success' });
            }
            setGroupLabel('');
            setGroupDescription('');
            setSelectedUsers([]);
            setInputValue('');
            dispatch(fetchGroups());
            navigate('/adminpanel/groups');
        } catch (error) {
            setSnackbar({
                open: true,
                message: editMode ? 'Ошибка при обновлении группы' : 'Ошибка при создании группы',
                severity: 'error',
            });
        }
    };

    const handleCloseSnackbar = () => setSnackbar({ ...snackbar, open: false });

    return (
        <Container maxWidth="sm" sx={{ paddingTop: '20px' }}>
            <Typography variant="h6" gutterBottom>
                {editMode ? 'Редактировать группу' : 'Добавить группу'}
            </Typography>
            <AnimatedBox delay={0.1}>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3, mt: 1 }}>
                    <TextField
                        label="Название группы"
                        variant="outlined"
                        fullWidth
                        value={groupLabel}
                        onChange={(e) => setGroupLabel(e.target.value)}
                    />
                    <TextField
                        label="Описание группы"
                        variant="outlined"
                        fullWidth
                        value={groupDescription}
                        onChange={(e) => setGroupDescription(e.target.value)}
                    />
                    <Autocomplete
                        multiple
                        freeSolo
                        disableCloseOnSelect
                        options={userList || []}
                        value={selectedUsers}
                        inputValue={inputValue}
                        onInputChange={(event, newInputValue) => setInputValue(newInputValue)}
                        onChange={(event, newValue) => setSelectedUsers(newValue)}
                        loading={loading}
                        getOptionLabel={(option) =>
                            typeof option === 'string' ? option : option.email || 'Email не указан'
                        }
                        noOptionsText="Ничего не найдено"
                        renderTags={(value, getTagProps) =>
                            value.map((option, index) => (
                                <Chip
                                    variant="outlined"
                                    label={typeof option === 'string' ? option : option.email}
                                    {...getTagProps({ index })}
                                    key={index}
                                />
                            ))
                        }
                        renderOption={(props, option) => (
                            <li {...props}>
                                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                    <Avatar>{roleEmojis[option.privileges?.[0]]?.emoji || '👤'}</Avatar>
                                    <Box>
                                        <Typography variant="body1">{option.email || option}</Typography>
                                        <Typography variant="body2" color="textSecondary">
                                            {roleEmojis[option.privileges?.[0]]?.label || 'Роль не указана'}
                                        </Typography>
                                    </Box>
                                </Box>
                            </li>
                        )}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Выберите пользователей или впишите email"
                                variant="outlined"
                                multiline
                                onKeyDown={handleKeyDown}
                                InputProps={{
                                    ...params.InputProps,
                                    endAdornment: (
                                        <>
                                            {emailRegex.test(inputValue.trim()) && inputValue.trim() !== '' && (
                                                <InputAdornment position="end">
                                                    <IconButton onClick={handleAddEmail} edge="end">
                                                        <CheckIcon color="primary" />
                                                    </IconButton>
                                                </InputAdornment>
                                            )}
                                            {params.InputProps.endAdornment}
                                        </>
                                    ),
                                }}
                            />
                        )}
                        PopperProps={{
                            disablePortal: true,
                            style: {
                                maxHeight: '170px',
                                overflowY: 'auto',
                            },
                        }}
                        ListboxProps={{
                            style: {
                                maxHeight: '170px',
                                overflow: 'auto',
                                paddingBottom: '70px',
                            },
                        }}
                    />
                    <Button variant="contained" color="primary" onClick={handleSubmit}>
                        {editMode ? 'Сохранить изменения' : 'Создать группу'}
                    </Button>
                </Box>
            </AnimatedBox>
            <Snackbar
                open={snackbar.open}
                autoHideDuration={3000}
                onClose={handleCloseSnackbar}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                <Alert onClose={handleCloseSnackbar} severity={snackbar.severity} sx={{ width: '100%' }}>
                    {snackbar.message}
                </Alert>
            </Snackbar>
        </Container>
    );
};

export default AddEditGroupScreen;
