import React, { useEffect, useState } from 'react';
import {
    Container,
    Box,
    Typography,
    Card,
    CardContent,
    CircularProgress,
    Snackbar,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Button,
    Alert,
    IconButton,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import { useNavigate } from 'react-router-dom';
import AnimatedBox from '../../../../components/MotionChild/AnimatedBox';
import { useDispatch, useSelector } from 'react-redux';
import { fetchGroups } from '../../../../service/redux/reducers/groupsSlice';
import { deleteGroup } from '../../../../api/api';
import { fetchUserList } from '../../../../service/redux/reducers/userListSlice';

const GroupsListScreen = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { groupsList, loading, error } = useSelector((state) => state.groups);
    const { userList } = useSelector((state) => state.usersList);

    const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });
    const [openDialog, setOpenDialog] = useState(false);
    const [selectedGroup, setSelectedGroup] = useState(null);

    useEffect(() => {
        dispatch(fetchGroups());
        dispatch(fetchUserList());
    }, [dispatch]);

    const handleDeleteClick = (group, event) => {
        event.stopPropagation();
        setSelectedGroup(group);
        setOpenDialog(true);
    };

    const handleDeleteConfirm = async () => {
        try {
            if (selectedGroup) {
                await deleteGroup(selectedGroup.id);
                setSnackbar({ open: true, message: 'Группа удалена', severity: 'success' });
                dispatch(fetchGroups());
            }
        } catch (error) {
            setSnackbar({ open: true, message: 'Ошибка при удалении группы', severity: 'error' });
        } finally {
            setOpenDialog(false);
            setSelectedGroup(null);
        }
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
        setSelectedGroup(null);
    };

    const handleCloseSnackbar = () => setSnackbar({ ...snackbar, open: false });

    const handleEditGroup = (group) => {
        navigate('/adminpanel/add-group', { state: { mode: 'edit', group } });
    };

    return (
        <Container maxWidth="sm" sx={{ paddingTop: '20px' }}>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <Typography variant="h6" gutterBottom>
                    Список групп
                </Typography>
                <IconButton onClick={() => navigate('/adminpanel/add-group')} aria-label="add group">
                    <AddIcon />
                </IconButton>
            </Box>
            {loading && <CircularProgress />}
            {error && <Alert severity="error">{error}</Alert>}
            <AnimatedBox delay={0.1}>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, mt: 1 }}>
                    {groupsList.length === 0 ? (
                        <Typography variant="body1">У вас еще нет созданных групп.</Typography>
                    ) : (
                        groupsList.map((group) => {
                            const displayNames = group.contacts.map((email) => {
                                const user = userList.find((u) => u.email === email);
                                return user ? `${user.first_name} ${user.last_name}` : email;
                            });
                            const visibleNames = displayNames.slice(0, 3).join(', ');
                            const extraCount = displayNames.length - 3;
                            return (
                                <Card
                                    key={group.id}
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'space-between',
                                        p: 1,
                                        cursor: 'pointer',
                                    }}
                                    onClick={() => handleEditGroup(group)}
                                >
                                    <CardContent sx={{ flex: 1, '&:last-child': { paddingBottom: 0 } }}>
                                        <Typography variant="subtitle1">{group.label}</Typography>
                                        <Typography variant="body2" sx={{ mb: 1 }}>
                                            {group.description}
                                        </Typography>
                                        <Typography variant="body2">
                                            {visibleNames}
                                            {extraCount > 0 && ` и еще ${extraCount}`}
                                        </Typography>
                                    </CardContent>
                                    <IconButton onClick={(e) => handleDeleteClick(group, e)} aria-label="delete group">
                                        <DeleteIcon />
                                    </IconButton>
                                </Card>
                            );
                        })
                    )}
                </Box>
            </AnimatedBox>
            <Dialog open={openDialog} onClose={handleCloseDialog}>
                <DialogTitle>Подтвердите удаление</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Вы уверены, что хотите удалить группу {selectedGroup ? selectedGroup.label : ''}?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialog}>Отмена</Button>
                    <Button onClick={handleDeleteConfirm} color="error" autoFocus>
                        Удалить
                    </Button>
                </DialogActions>
            </Dialog>
            <Snackbar
                open={snackbar.open}
                autoHideDuration={3000}
                onClose={handleCloseSnackbar}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                <Alert onClose={handleCloseSnackbar} severity={snackbar.severity} sx={{ width: '100%' }}>
                    {snackbar.message}
                </Alert>
            </Snackbar>
        </Container>
    );
};

export default GroupsListScreen;
