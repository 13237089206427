import React from 'react';
import { useSelector } from 'react-redux';

const API_SUPP_URL = process.env.REACT_APP_SUPPORT_URL;

const SupportScreen = () => {
    const telegramWebApp = window.Telegram.WebApp;
    const tgId = telegramWebApp?.initDataUnsafe?.user?.id;
    const initData = telegramWebApp.initData
    const { email } = useSelector((state) => state.auth);

    return (
        <div style={{ width: '100%', height: '90vh', overflow: 'hidden', backgroundColor: 'transparent'}}>
            <iframe
                src={`${API_SUPP_URL}/?project=talk-room&tg_id=${tgId}&email=${email}&init_data=${encodeURIComponent(initData)}`}
                title="Support"
                style={{ width: '100%', height: '100%', border: 'none', backgroundColor: 'transparent'}}
            />
        </div>
    );
};

export default SupportScreen;
