import React, { useRef, useState } from 'react';
import { Modal, Box, Typography, IconButton, Fade, Backdrop, CircularProgress } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import Slider from 'react-slick';
import ReactPlayer from 'react-player';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    paddingTop: 0,
    maxWidth: 600,
    width: '90%',
    maxHeight: '90vh',
    overflowY: 'auto',
    touchAction: 'pan-y',
};

const videoData = [
    {
        url: 'https://storage.yandexcloud.net/digital-library/talk_room/27032025/2.mp4',
        description: (
            <ul>
                <li>Добавлена возможность создавать группы пользователей</li>
                <li>Вы можете создать неограниченное количество групп</li>
                <li>Вы можете создать неограниченное количество пользователей внутри группы</li>
            </ul>
        ),
    },
    {
        url: 'https://storage.yandexcloud.net/digital-library/talk_room/27032025/1.mp4',
        description: (
            <ul>
                <li>При создании группы пользователей вы сможете ввести краткое наименование группы</li>
                <li>При создании группы пользователей вы сможете ввести описание группы</li>
            </ul>
        ),
    }
];

const WhatsNewModal = ({ open, handleClose, currentVersion }) => {
    const sliderRef = useRef(null);
    const playerRefs = useRef([]);
    const [loadingStates, setLoadingStates] = useState(
        videoData.map(() => true)
    );

    const settings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
        swipe: true,
        swipeToSlide: true,
        adaptiveHeight: true,
        cssEase: 'ease-in-out',
        beforeChange: (current, next) => {
            if (playerRefs.current[current]) {
                playerRefs.current[current].seekTo(0);
                playerRefs.current[current].getInternalPlayer().pause();
            }
        },
    };

    const handlePlayerReady = (index) => {
        setLoadingStates((prevStates) => {
            const newStates = [...prevStates];
            newStates[index] = false;
            return newStates;
        });
    };

    const handlePlayerError = (index, error) => {
        console.error(`Ошибка загрузки видео ${index + 1}:`, error);
        setLoadingStates((prevStates) => {
            const newStates = [...prevStates];
            newStates[index] = false;
            return newStates;
        });
    };

    return (
        <Modal
            open={open}
            onClose={handleClose}
            closeAfterTransition
            slots={{ backdrop: Backdrop }}
            slotProps={{
                backdrop: {
                    timeout: 500,
                },
            }}
        >
            <Fade in={open}>
                <Box sx={style}>
                    <Box>
                        <Typography variant="h6" component="h2">
                            Что нового в версии {currentVersion}
                        </Typography>
                        <IconButton
                            aria-label="close"
                            onClick={handleClose}
                            sx={{ position: 'absolute', right: 12, top: 0 }}
                        >
                            <CloseIcon />
                        </IconButton>
                    </Box>
                    <Box sx={{ mt: 1 }}>
                        <Slider {...settings} ref={sliderRef}>
                            {videoData.map((video, index) => (
                                <Box key={index} sx={{ position: 'relative', touchAction: 'pan-y' }}>
                                    {loadingStates[index] && (
                                        <Box
                                            sx={{
                                                position: 'absolute',
                                                top: 0,
                                                left: 0,
                                                width: '100%',
                                                height: '100%',
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                backgroundColor: 'rgba(255, 255, 255, 0.7)',
                                                zIndex: 1,
                                                borderRadius: '4px',
                                            }}
                                        >
                                            <CircularProgress />
                                        </Box>
                                    )}
                                    <ReactPlayer
                                        ref={(player) => (playerRefs.current[index] = player)}
                                        url={video.url}
                                        title={`Видео ${index + 1}`}
                                        width="100%"
                                        height="350px"
                                        controls
                                        playing={true}
                                        muted={true}
                                        onReady={() => handlePlayerReady(index)}
                                        onError={(e) => handlePlayerError(index, e)}
                                        style={{ border: 'none', borderRadius: '4px' }}
                                    />
                                    <Box
                                        sx={{
                                            mt: 1,
                                            ul: {
                                                paddingLeft: '20px',
                                                listStyleType: 'disc',
                                                color: 'text.primary',
                                                fontFamily: 'Poppins, Arial, sans-serif',
                                                '& li': {
                                                    marginBottom: '8px',
                                                    lineHeight: 1.5,
                                                    fontSize: '0.95rem',
                                                    color: 'text.secondary',
                                                },
                                            },
                                        }}
                                    >
                                        {video.description}
                                    </Box>
                                </Box>
                            ))}
                        </Slider>
                    </Box>
                </Box>
            </Fade>
        </Modal>
    );
};

export default WhatsNewModal;
