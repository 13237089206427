import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { login as loginAPI } from "../../../api/api";

export const loginUser = createAsyncThunk('auth/loginUser', async (initData) => {
    const response = await loginAPI(initData);
    return response.data.data;
});

const authSlice = createSlice({
    name: 'auth',
    initialState: {
        user: null,
        email: null,
        group_id: null,
        loading: false,
        error: null,
    },
    reducers: {
    },
    extraReducers: (builder) => {
        builder
            .addCase(loginUser.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(loginUser.fulfilled, (state, action) => {
                state.loading = false;
                const userData = action.payload;
                state.user = userData.user;
                state.group_id = userData.user.groups_ids[0];
                state.email = userData.user.email;
            })
            .addCase(loginUser.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            });
    },
});

export default authSlice.reducer;
