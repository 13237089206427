import React, { useState, useEffect } from 'react';
import { BottomNavigation, BottomNavigationAction, useTheme } from '@mui/material';
import BookmarkAddIcon from '@mui/icons-material/BookmarkAdd';
import EventIcon from '@mui/icons-material/Event';
import FeedbackIcon from '@mui/icons-material/Feedback';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import { useNavigate, useLocation } from 'react-router-dom';
import styles from './BottomNavBar.module.css';

const BottomNavBar = ({ bottomPadding, privileges }) => {
    const [value, setValue] = useState(0);
    const navigate = useNavigate();
    const location = useLocation();
    const theme = useTheme();

    useEffect(() => {
        if (location.pathname === '/' || location.pathname.startsWith('/rooms')) {
            setValue(0);
        } else if (location.pathname === '/reservation') {
            setValue(1);
        } else if (location.pathname.startsWith('/adminpanel')) {
            setValue(2);
        } else if (location.pathname === '/feedback') {
            setValue(3);
        }
    }, [location.pathname]);

    const handleNavigation = (event, newValue) => {
        setValue(newValue);
        switch (newValue) {
            case 0:
                navigate('/');
                break;
            case 1:
                navigate('/reservation');
                break;
            case 2:
                navigate('/adminpanel');
                break;
            case 3:
                navigate('/support');
                break;
            default:
                break;
        }
    };

    return (
        <BottomNavigation
            value={value}
            onChange={handleNavigation}
            showLabels
            className={styles.bottomNav}
            sx={{
                backgroundColor: theme.palette.mode === 'dark' ? '#1e1e1e' : '#ffffff',
                boxShadow:
                    theme.palette.mode === 'dark'
                        ? '0px -2px 10px rgba(0, 0, 0, 0.7)'
                        : '0px -2px 10px rgba(0, 0, 0, 0.1)',
                paddingBottom: `${bottomPadding}px`,
                paddingLeft: '1em',
                paddingRight: '0.2em',
                '& .MuiBottomNavigationAction-root': {
                    color: theme.palette.mode === 'dark' ? '#ffffff' : '#1D3557',
                    '&.Mui-selected': {
                        color: theme.palette.mode === 'dark' ? '#7A2EDC' : '#1D3557',
                    },
                },
            }}
        >
            <BottomNavigationAction label="Бронирование" icon={<BookmarkAddIcon />} />
            <BottomNavigationAction label="Брони" icon={<EventIcon />} />
            {(privileges.includes('global_admin') || privileges.includes('admin')) && (
                <BottomNavigationAction
                    sx={{
                        '& .MuiBottomNavigationAction-label': {
                            fontSize: '0.75rem',
                        },
                        '&.Mui-selected .MuiBottomNavigationAction-label': {
                            fontSize: '0.75rem',
                        },
                    }}
                    label="Управление"
                    icon={<SupervisorAccountIcon />}
                />
            )}
            <BottomNavigationAction label="Поддержка" icon={<FeedbackIcon />} />
        </BottomNavigation>
    );
};

export default BottomNavBar;
